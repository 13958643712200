<template>
	<v-timeline align-top dense v-if="!!items" class="mt-2 mb-8">
		<v-timeline-item v-for="(item,index) in items" :key="index" small right>
			<span class="font-weight-bold">{{formatTime(item.time)}}<span class="ml-8 mr-2">就诊医生：{{item.operatorName}}</span></span>
			<v-chip v-for="tag in item.mrs.tags" :key="tag" color="success" small class="ml-1">{{tag}}</v-chip>
            <v-btn v-if="!!item.id" fab elevation="1" x-small class="ml-6" @click.stop="editItem(item.mrindex)"><v-icon>mdi-pencil</v-icon></v-btn>
            <v-btn v-if="!!item.id" fab elevation="1" x-small class="ml-2" @click.stop="remove(item.mrindex)"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
			<div>
				<span v-for="mri in formatItems(item.mrs)">
					<span style="font-weight:bold;">{{mri.key}}：</span>
					<span>{{mri.value}}　</span>
				</span>
			</div>
			<div v-if="item.mos && item.mos.length > 0" class="mt-3">
				<span style="font-weight:bold;">医嘱项目</span>
				<v-data-table :items="item.mos" :headers="moHeaders" group-by="mi.项目分类" dense style="max-width:1000px;border-bottom:1px solid #ccc;" :items-per-page="-1" hide-default-footer>
					<template v-slot:group.header="{isOpen, toggle, group}">
						<th colspan="1">
							<v-icon @click="toggle"
								>{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
							</v-icon>
							{{ group }}
						</th>
					</template>
				</v-data-table>
			</div>
		</v-timeline-item>
	</v-timeline>
</template>

<script>
    import {formatTime} from '../utils'

	export default {
		props: {
			items: {
				type: Array,
				default: () => []
			}
		},
        data() {
            return {
				mritems: [
					'主诉',
					'现病史',
					'既往史',
					'家族史',
					'手术史',
					'过敏史',
					'体格检查',
					'辅助检查',
					'诊断',
					'处置'
				],
				moHeaders: [
					{text:'分类', value:'mi.项目分类', width:100},
					{text:'项目', value:'mi.项目名称', width:200},
					{text:'剂量', value:'mi.基本剂量', width:100},
					{text:'用法', value:'mo.routes', width:120},
					{text:'频次', value:'mo.times', width:120},
					{text:'天数', value:'mo.days', width:80},
					{text:'数量', value:'mo.amount', width:80},
				],
			}
		},
		created() {
			this.formatTime = formatTime;
		},
		methods: {
			formatItems(items) {
				if (!items) return [];
				let r = [];
				this.mritems.forEach(x => {
					if (items[x]) r.push({key:x, value:items[x]});
				});
				return r;
			},
			editItem(index) {
				this.$emit('edit', index);
			},
			remove(index) {
				this.$emit('remove', index);
			},
		},
	}
</script>