<template>
	<div v-if="authed">
		<splitpanes horizontal class="default-theme" style="height:calc(100vh - 52px)" @resized="setTableHeight" v-resize="setTableHeight">
			<pane min-size="25" size="40" ref="toppane">
				<div>
					<v-data-table :headers="headers" :items="items" item-key="_id"
						show-select single-select v-model="selected"
						:options.sync="options" :server-items-length="totalItems"
						:footer-props="{ itemsPerPageOptions: [10, 30, 50] }" fixed-header :height="tableheight"
						:loading="loading" loading-text="加载中...">
						<template v-slot:top>
							<div class="d-flex flex-wrap pt-3 pb-1 pl-1" style="gap:16px" ref="tabletopbar">
								<v-btn color="primary" @click.stop="addItem">新建病案</v-btn>
								<v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="10"
									transition="scale-transition" offset-y min-width="290px">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="pdate1" label="预约门诊日期起" readonly v-bind="attrs"
											v-on="on" dense hide-details clearable style="flex: 0 1 auto;max-width:150px;"></v-text-field>
									</template>
									<v-date-picker v-model="pdate1" @input="menu1 = false" no-title scrollable></v-date-picker>
								</v-menu>
								<v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="10"
									transition="scale-transition" offset-y min-width="290px">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="pdate2" label="预约门诊日期止" readonly v-bind="attrs"
											v-on="on" dense hide-details clearable style="flex: 0 1 auto;max-width:150px;"></v-text-field>
									</template>
									<v-date-picker v-model="pdate2" @input="menu2 = false" no-title scrollable></v-date-picker>
								</v-menu>
								<v-text-field label="姓名" v-model.trim="pname" style="flex: 0 1 auto" hide-no-data hide-details dense
									clearable append-icon="search" @click:append="reloadData" @change="reloadData"/>
								<v-text-field label="证件号码" v-model.trim="pidcard" style="flex: 0 1 auto" hide-no-data hide-details dense
									clearable append-icon="search" @click:append="reloadData" @change="reloadData"/>
								<v-text-field label="病案号" v-model.trim="pid" style="flex: 0 1 auto" hide-no-data hide-details dense
									clearable append-icon="search" @click:append="reloadData" @change="reloadData"/>
							</div>
							<v-divider/>
						</template>
						<template v-slot:item.pii.username="props">
							<v-edit-dialog :return-value.sync="props.item.pii.username" large
								save-text="保存" cancel-text="取消" @save="saveItem('pii.username', props)">
							{{props.item.pii.username}}
								<template v-slot:input>
									<v-text-field label="姓名" v-model.trim="props.item.pii.username" :disabled="!!props.item.anesEvalDate"/>
								</template>
							</v-edit-dialog>
						</template>
						<template v-slot:item.pii.phone="props">
							<v-edit-dialog :return-value.sync="props.item.pii.phone" large
								save-text="保存" cancel-text="取消" @save="saveItem('pii.phone', props)">
							{{props.item.pii.phone}}
								<template v-slot:input>
									<v-text-field label="电话" v-model.trim="props.item.pii.phone"/>
								</template>
							</v-edit-dialog>
						</template>
						<template v-slot:item.age="{ item }">
							{{getAge(item)}}
						</template>
						<template v-slot:item.apntClncDate="{ item }">
							{{formatTime(item.apntClncDate)}}
						</template>
						<template v-slot:item.lastDiagTime="{ item }">
							{{getLastDiagTime(item)}}
						</template>
						<template v-slot:item.actions="{ item }">
							<v-icon :disabled="!!item.apntClncDate" class="mr-2" @click.stop="deleteClinic(item)">mdi-trash-can-outline</v-icon>
						</template>
					</v-data-table>
				</div>
			</pane>
			<pane min-size="20" size="60" v-if="(selected.length > 0)">
				<div style="max-height: 100%" class="overflow-y-auto">
					<v-tabs>
						<v-tab :disabled="!canEdit">病历记录</v-tab>
						<v-tab-item :disabled="!canEdit">
							<div>
								<v-btn class="ml-8 mt-6 mb-4" rounded width="120" color="primary" @click.stop="addRecord">添加病历</v-btn>
								<diagnose-history :items="MedicalHistory" @edit="editRecord" @remove="removeRecord"/>
							</div>
						</v-tab-item>
						<v-tab :disabled="!canCharge">计费</v-tab>
						<v-tab-item :disabled="!canCharge">
							<v-form class="pt-4 pl-4" style="width:200px">
								<v-text-field label="费用" type="number" hide-spin-buttons v-model.number="clinicCharge"/>
								<v-btn color="primary" @click.stop="saveCharge">保存</v-btn>
							</v-form>
						</v-tab-item>
					</v-tabs>
				</div>
			</pane>
		</splitpanes>
		<v-dialog v-model="dlg" width="500">
			<v-card>
				<v-card-title>新建病案</v-card-title>
				<v-card-text>
					<v-form ref="formNew">
						<v-container>
							<v-row dense>
								<v-col cols="6"><v-text-field dense label="客户姓名" v-model.trim="neworder.pii.username" :rules="cnltRules"/></v-col>
								<v-col cols="6"><v-text-field dense label="手机号" v-model.trim="neworder.pii.phone" :rules="phoneRules"/></v-col>
							</v-row>
							<v-row dense>
								<v-col cols="4"><v-select dense label="证件类型" :items="['身份证','护照','军官证','其他']" v-model="neworder.pii.idtype" @change="parseIdcard"/></v-col>
								<v-col cols="8"><v-text-field dense label="证件号码" v-model.trim="neworder.pii.idcard" :rules="cnltRules" :error-messages="iderr ? '身份证号无效' : ''" @input="parseIdcard"/></v-col>
							</v-row>
							<v-row dense>
								<v-col cols="4"><v-select dense label="性别" :items="['男','女']" v-model="neworder.pii.gender" :rules="cnltRules" :readonly="neworder.pii.idtype==='身份证'"/></v-col>
								<v-col cols="8">
									<v-menu v-model="menuDob" :close-on-content-click="false" :nudge-right="10"
										transition="scale-transition" offset-y min-width="290px">
										<template v-slot:activator="{ on, attrs }">
											<v-text-field v-model="neworder.pii.dob" label="出生日期" readonly v-bind="attrs"
												v-on="on" dense :rules="cnltRules"></v-text-field>
										</template>
										<v-date-picker v-model="neworder.pii.dob" @change="menuDob=false" no-title scrollable :readonly="neworder.pii.idtype==='身份证'"></v-date-picker>
									</v-menu>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="4"><v-select dense label="健康顾问" :items="cnslts" item-text="name" item-value="_id" return-object v-model="neworderconsultant"
									:rules="cnltRules" :readonly="!canEdit"/></v-col>
								<v-col cols="8"><v-text-field label="备注" v-model="neworder.comment" dense/></v-col>
							</v-row>
						</v-container>
					</v-form>
				</v-card-text>
				<v-divider/>
				<v-card-actions>
					<v-spacer/>
					<v-btn color="primary" :loading="loading" @click.stop="saveNew">确定</v-btn>
					<v-btn @click.stop="dlg=false">取消</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<diagnose v-model="diagnose" :patient="selected[0]" :mrindex="mrindex" @change="fetchMr"/>
	</div>
	<div v-else>
		无此权限
	</div>
</template>

<script>
	import { Splitpanes, Pane } from 'splitpanes'
	import 'splitpanes/dist/splitpanes.css'
	import {formatTime, formatDate, calculateAge, checkIdCard} from '../utils'
	import DiagnoseHistory from '../components/DiagnoseHistory.vue';
	import Diagnose from '../components/Diagnose.vue';

	export default {
		data() {
			return {
				authed: false,
				canEdit: false,
				canCharge: false,
				headers: [
                    {text:'病案号', value:'pid', width:100},
					{text:'姓名', value:'pii.username', width:100},
					{text:'电话', value:'pii.phone', width:100},
					{text:'证件号', value:'pii.idcard', width:120},
					{text:'性别', value:'pii.gender', width:80},
					{text:'年龄', value:'age', width:80},
                    {text:'预约门诊时间', value:'apntClncDate', width:180},
					{text:'最新诊断时间', value:'lastDiagTime', width:180},
					{text:'费用', value:'clinicCharge', width:100},
                    {text:'健康顾问', value:'consultantName', width:100},
					{text:'备注', value:'comment', width:200},
                    {text:'操作', value:'actions', width:100, sortable: false},
				],
				items: [],
				loading: false,
				selected: [],
				options: {sortBy:['apntClncDate'], sortDesc:[false]},
				totalItems: 0,
				pdate1: '',
				menu1: false,
				pdate2: '',
				menu2: false,
				pname: '',
				pidcard: '',
				pid: '',
				dlg: false,
				neworder: {
					pii: {
						idtype: '身份证'
					},
					region: '北京丰联',
				},
				cnslts: [],
				neworderconsultant: null,
				cnltRules: [v => !!v || '此项为必填'],
				phoneRules: [v => (/^[1][3,4,5,6,7,8,9][0-9]{9}$/).test(v) || '手机号格式不正确'],
				numberRules: [v => (/^-?[0-9]+(.[0-9]*)?$/g).test(v) || '只允许数字'],
				menuDob: false,
				iderr: false,
				diagnose: false,
				clinicCharge: null,
				tableheight: undefined,
				mrindex: -1,
				MedicalHistory: [],
			}
		},
		created() {
			this.formatTime = formatTime;
			this.formatDate = formatDate;
			this.pdate1 = formatDate(Date.now());
			this.pdate2 = formatDate(Date.now());
		},
		mounted() {
			this.authed = this.$hasPrivilege(['门诊','门诊计费','健康顾问']);
			if (!this.authed) return;
			this.canEdit = this.$hasPrivilege('门诊');
			this.canCharge = this.$hasPrivilege('门诊计费');
			this.fetchConsultants();
		},
		methods: {
			setTableHeight() {
				if (!this.$vuetify.breakpoint.mobile && this.selected.length === 0) {
					this.tableheight = undefined;
					return;
				}
				const tableFooterHeight = 59;
				const ttbh = this.$refs.tabletopbar.getBoundingClientRect().height;
				setTimeout(() => {
					const paneHeight = this.$refs.toppane.$el.getBoundingClientRect().height;
					this.tableheight = paneHeight - tableFooterHeight - ttbh;
					if (this.selected.length > 0) this.$nextTick(() => this.$vuetify.goTo(".v-data-table__selected", { container: ".v-data-table__wrapper" }) );
				}, 200)
			},
			getAge(item) {
				return calculateAge(item.pii.dob);
			},
			getLastDiagTime(item) {
				if (item.MedicalHistory && item.MedicalHistory.length > 0) {
					return formatTime(item.MedicalHistory.slice(-1)[0].time);
				}
				return '';
			},
			parseIdcard() {
				this.iderr = false;
				if (this.neworder.pii.idtype==='身份证' && checkIdCard(this.neworder.pii.idcard)) {
					const idcard = this.neworder.pii.idcard;
					this.neworder.pii.dob = idcard.substr(6,8).replace(/(.{4})(.{2})/,"$1-$2-");
					this.neworder.pii.gender = idcard[16] % 2 === 1 ? '男' : '女';
				}
			},
			addItem() {
				this.dlg = true;
				this.$nextTick(()=>this.$refs.formNew.resetValidation());
			},
			async saveNew() {
				this.$refs.formNew.validate();
				if (!this.$refs.formNew.validate()) return;
				if (this.neworder.pii.idtype==='身份证' && !checkIdCard(this.neworder.pii.idcard)) {
					this.iderr = true;
					return;
				}
				this.neworder.consultantId = this.neworderconsultant._id;
				this.neworder.consultantName = this.neworderconsultant.name;
				this.neworder.consultantPhone = this.neworderconsultant.phone;
				this.loading = true;
				try {
					const res = await this.$tcbapp.callFunction({name:"wp2mp", data:{funcname:'createClinicOrder',
						data: this.neworder
					}});
					if (res.result.err) {
						this.$dialog.message.error(res.result.err);
					} else {
						this.neworder = this.$options.data().neworder;
						this.dlg = false;
						this.pname = '';
						this.pidcard = '';
						this.pid = res.result.pid;
						await this.fetchData();
						const c = this.items.find(x => x._id === res.result.id);
						this.selected = [c];
					}
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			async deleteClinic(item) {
				const res = await this.$dialog.confirm({
					text: '确定要删除此次门诊？',
					title: '提示'
				});
				if (!res) return;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp", data:{funcname:'deleteClinicOrder',
						data:{
							id: item._id,
						}
					}});
					await this.fetchData();
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('删除失败');
				}
			},
			async saveItem(key, props) {
				await this.$tcbapp.callFunction({name:"wp2mp", data:{funcname:'editInfo',
					data:{
						id: props.item._id,
						key,
						value: props.value
					}
				}});
			},
			async fetchConsultants() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				const filter = {rank:_.gt(0)};
				if (this.$hasPrivilege('门诊')) {
					//admin也有此权限
				} else if (this.$hasPrivilege('健康顾问')) {
					const u = this.$getCurrentUser();
					filter['_id'] = u.consultantId;
				}
				try {
					const res = await db.collection('consultants').where(filter).orderBy('rank', 'desc').field({name:true,phone:true}).get();
					this.cnslts = res.data;
					if (this.cnslts.length === 1) this.neworderconsultant = this.cnslts[0];
				} catch(err) {
					console.error(err);
				}
			},
			async reloadData() {
				this.selected = [];
				if (this.options.page !== 1) {
					this.options.page = 1;	//自动触发fetchData
					return;
				}
				await this.fetchData();
			},
			async fetchData() {
				this.loading = true;
				try {
					const db = this.$tcbapp.database();
					const _ = db.command;
					let filter = [{category:'clinic'}];
					if (this.$hasPrivilege('门诊')) {
						//admin也有此权限
					} else if (this.$hasPrivilege('健康顾问')) {
						const u = this.$getCurrentUser();
						filter.push({consultantId: u.consultantId});
					}
					const t1 = this.pdate1 ? new Date(this.pdate1 + 'T' + '00:00:00+08:00') : null;
					const t2 = this.pdate2 ? new Date(this.pdate2 + 'T' + '23:59:59+08:00') : null;
					if (t1 && t2) {
						filter.push({apntClncDate:_.gt(t1).lt(t2)});
					} else if (this.pdate1) {
						filter.push({apntClncDate:_.gt(t1)});
					} else if (this.pdate2) {
						filter.push({apntClncDate:_.lt(t2)});
					}
					if (this.pname) {
						filter.push({'pii.username':this.pname});
					}
					if (this.pidcard) {
						filter.push({'pii.idcard':this.pidcard});
					}
					if (this.pid) {
						filter.push({pid:this.pid});
					}
					filter.push({hide:_.neq(true)});
					const countRes = await db.collection('wp2order').where(_.and(filter)).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp2order').where(_.and(filter)).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			async fetchMr() {
				this.MedicalHistory = [];
				const db = this.$tcbapp.database();
				const _ = db.command;
				try {
					const res = await db.collection('wp2order')
						.where({
							pid: this.selected[0].pid,
							MedicalHistory: _.exists(true),
						})
						.field({MedicalHistory:true})
						.get();
					const r = res.data.find(x => x._id === this.selected[0]._id);
					if (r) {
						this.selected[0].MedicalHistory = r.MedicalHistory;
					}
					this.MedicalHistory = res.data
						.flatMap(x => {
							x.MedicalHistory.forEach((y, index) => {
								if (x._id === this.selected[0]._id) {
									y.id = x._id;
									y.mrindex = index;
								}
							});
							return x.MedicalHistory;
						})
						.sort((a, b) => a.time - b.time);
				} catch(err) {
					console.error(err);
				}
			},
			addRecord() {
				this.mrindex = -1;
				this.diagnose = true;
			},
			editRecord(idx) {
				this.mrindex = idx;
				this.diagnose = true;
			},
			async removeRecord(index) {
				const res = await this.$dialog.warning({
					text: '确定要删除此项记录？',
					title: '提示'
				});
				if (!res) return;
				const id = this.selected[0]._id;
				this.loading = true;
				try {
					const res = await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname:'removeMedicalRecord',
							data: {
								id,
								index,
							}
						}
					});
					if (!res.result.ret) {
						this.$dialog.error({title:'删除失败', text:res.result.msg});
						return;
					}
					this.fetchMr();
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('删除失败');
				} finally {
					this.loading = false;
				}
			},
			async saveCharge() {
				if ((this.clinicCharge === undefined)||(this.clinicCharge === null)) {
					this.$dialog.message.error('不能保存空内容');
					return;
				}
                this.loading = true;
				const id = this.selected[0]._id;
				try {
					const res = await this.$tcbapp.callFunction({name:"wp2mp", data:{
						funcname:'saveClinicCharge',
						data: {
							id,
							clinicCharge: this.clinicCharge,
						}
					}});
					this.$dialog.message.success('保存完成');
					await this.fetchData();
				} catch(err) {
					console.error(err);				
					this.$dialog.message.error('保存失败');
				}
				this.loading = false;
			},
		},
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			selected(newitem, olditem) {
				if (newitem.length === 0) return;
				this.clinicCharge = newitem[0].clinicCharge;
				this.fetchMr();
			},
			pdate1(newv, oldv) {
				if (newv && this.pdate2) {
					const t1 = this.pdate1 ? new Date(this.pdate1 + 'T' + '00:00:00+08:00') : null;
					const t2 = this.pdate2 ? new Date(this.pdate2 + 'T' + '23:59:59+08:00') : null;
					if (t1 > t2) {
						this.pdate2 = newv;
					}
				}
				this.reloadData();
			},
			pdate2(newv, oldv) {
				if (newv && this.pdate1) {
					const t1 = this.pdate1 ? new Date(this.pdate1 + 'T' + '00:00:00+08:00') : null;
					const t2 = this.pdate2 ? new Date(this.pdate2 + 'T' + '23:59:59+08:00') : null;
					if (t1 > t2) {
						this.pdate1 = newv;
					}
				}
				this.reloadData();
			},
		},
		components: { Splitpanes, Pane, DiagnoseHistory, Diagnose }
	}
</script>
