<template>
	<v-dialog persistent fullscreen v-model="dialog">
		<v-sheet>
			<div class="d-flex px-4 py-2">
				<div class="d-flex justify-space-between flex-shrink-1" style="gap:16px;font-size:18px;line-height:44px;">
					<div>
						<v-btn color="primary" width="120" @click.stop="endEdit"><v-icon>arrow_back_ios</v-icon>返回</v-btn>
						<v-btn color="primary" width="120" class="ml-2" :disabled="!authed" :loading="loading" @click.stop="save">保存</v-btn>
						<v-btn color="primary" width="120" class="ml-2" :disabled="index===-1" v-print="printObj">打印病历</v-btn>
						<v-btn color="primary" width="120" class="ml-2" :disabled="index===-1" v-print="printObjMo">打印处方</v-btn>
					</div>
					<template v-if="!!patient && !!patient.pii">
						<v-text-field readonly hide-details style="width:110px" label="病案号" :value="patient.pid"/>
						<v-text-field readonly hide-details style="width:90px" label="姓名" :value="patient.pii.username"/>
						<v-text-field readonly hide-details style="width:30px" label="性别" :value="patient.pii.gender"/>
						<v-text-field readonly hide-details style="width:30px" label="年龄" :value="getAge(patient.pii.dob)"/>
						<v-text-field readonly hide-details style="width:120px" label="电话" :value="patient.pii.phone"/>
					</template>
					<div>
						<v-btn color="primary" @click.stop="tplSheet=true" style="width:100px;margin-left:4px;" outlined :disabled="!authed">历史与模板</v-btn>
					</div>
				</div>
			</div>
			<hr style="margin:1px 0"/>
			<div>
				<splitpanes horizontal class="default-theme" style="height:calc(100vh - 68px)">	<!-- 68px是避免出右侧滚动条的最小值 -->
					<pane min-size="25" size="100" class="d-flex">
						<Medicalrecord :mrs="mrs" :mos="mos" @updatetemplate="fetchTemplates" :readonly="!authed" :onappend="onappend"/>
					</pane>
				</splitpanes>
			</div>
		</v-sheet>
		<div style="display:none" v-if="!!patient && !!patient.pii && !!patient.MedicalHistory">
			<div id="printme4d" style="line-height:32px;width:100vw;position:relative;border:1px solid #fff;">
				<div style="position:absolute;top:30px;left:20px;right:20px;">
					<img src='/logo.png' height="70"/>
					<div style="position:absolute;top:0;right:0;text-align:right;line-height:16px;font-size:8px;">
						<p>北京华三诊所 Beijing Huasan Clinic</p>
						<p>北京市朝阳区朝阳门外大街18号丰联广场2层，邮编：100020</p>
						<p>2nd Floor, Fenglian Plaza, No.18 Chaoyangmenwai Street,</p>
						<p>Chaoyang District, Beijing, 100020 P.R.CHINA</p>
						<p>TEL/电话：400-160-3939</p>
					</div>
				</div>
				<div style="font-size:26px;font-weight:bold;width:100%;text-align:center;margin-top:112px;margin-bottom:20px;">病历记录</div>
				<div style="width:92%;margin:0 auto;">
					<table class="prttable">
						<tr>
							<td width="30%">姓名：{{patient.pii.username}}</td>
							<td width="20%">性别：{{patient.pii.gender}}</td>
							<td width="20%">年龄：{{getAge(patient.pii.dob)}}岁</td>
							<td>病案号：{{patient.pid}}</td>
						</tr>
						<tr>
							<td width="20%">科室：内科</td>
							<td width="20%">医师：{{curoperatorName}}</td>
							<td width="20%"/>
							<td width="40%">就诊日期：{{curdate}}</td>
						</tr>
					</table>
				</div>
				<div style="height:65vh;border-top:1px solid #999;border-bottom:1px solid #999;">
					<table class="prttable" style="padding-top:16px;padding-right:50px;">
						<tr v-for="item in mritems" v-if="!!mrs[item]" valign="top">
							<td width="18%" align="right">{{item}}： </td>
							<td width="2%"/>
							<td width="80%">{{mrs[item]}}</td>
						</tr>
					</table>
				</div>
				<div style="width:96%;margin:0 auto;padding-top:20px;padding-left:300px;">
					<p style="font-size:18px!important;">医师签名（签章）：<img height="60" align="top" :src="cursignature"/></p>
				</div>
			</div>
			<div id="printmo" style="line-height:32px;width:100vw;position:relative;border:1px solid #fff;">
				<div style="font-size:26px;font-weight:bold;width:100%;text-align:center;margin-top:10px;margin-bottom:20px;">北京华三诊所 处方笺</div>
				<div style="width:92%;margin:0 auto;">
					<table class="prttable">
						<tr>
							<td width="30%">姓名：{{patient.pii.username}}</td>
							<td width="20%">性别：{{patient.pii.gender}}</td>
							<td width="20%">年龄：{{getAge(patient.pii.dob)}}岁</td>
							<td>病案号：{{patient.pid}}</td>
						</tr>
						<tr>
							<td width="20%">科室：内科</td>
							<td width="20%">医师：{{curoperatorName}}</td>
							<td width="20%"/>
							<td width="40%">就诊日期：{{curdate}}</td>
						</tr>
					</table>
					<div v-if="mrs['诊断']" style="font-size:15px;">临床诊断：{{mrs['诊断']}}</div>
				</div>
				<div style="height:26vh;border:1px solid #999;padding:12px;">
					<div style="font-size:32px;font-weight:bold;">℞：</div>
					<table class="prttable1" style="padding-top:16px;padding-right:50px;">
						<thead>
							<tr>
								<th align="left">药品名称</th>
								<th align="left">用量</th>
								<th align="left">使用方法</th>
								<th align="left">周期</th>
								<th align="left">备注</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="item in mos.filter(x=>x.mi['项目分类']==='西药类'||x.mi['项目分类']==='中药类')" valign="top">
								<td width="50%">{{item.mi.项目名称}} {{item.mi.规格}}</td>
								<td width="10%">{{item.mo.dosis}}{{item.mi.剂量单位}}</td>
								<td width="14%">{{item.mo.routes}} {{item.mo.times}}</td>
								<td width="8%">{{item.mo.days}}天</td>
								<td width="18%">{{item.mi.备注}}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div style="font-size:10px;padding-left:16px;">
					用药提示：门诊处方有效期为3天；多科室就诊可能造成重复用药，请注意合理用药。如有任何用药问题请咨询医院药房。
				</div>
				<div style="width:96%;margin:0 auto;padding-top:20px;padding-left:400px;">
					<p style="font-size:16px!important;">医师签名（签章）：<img height="40" align="top" :src="cursignature"/></p>
				</div>
			</div>
		</div>
		<v-bottom-sheet v-model="tplSheet">
			<v-sheet height="616" class="d-flex">
				<div class="diagnoserightpanel">
					<div style="flex-grow:1;">
						<v-tabs v-model="tab">
							<v-tab>历史就诊</v-tab>
							<v-tab>病历模板</v-tab>
							<v-tab>医嘱模板</v-tab>
						</v-tabs>
						<v-tabs-items v-model="tab" style="height:100%;">
							<v-tab-item>
								<v-list dense v-if="!!patient && !!patient.MedicalHistory">
									<v-list-item-group color="primary" v-model="selectedHistory">
										<v-list-item v-for="li in patient.MedicalHistory" :key="li.id">
											<v-list-item-title>{{formatDate(li.time)}}</v-list-item-title>
											<v-list-item-title class="text-right">{{li.operatorName}}</v-list-item-title>
										</v-list-item>
									</v-list-item-group>
								</v-list>
							</v-tab-item>
							<v-tab-item>
								<v-list dense style="height:510px;overflow-y:auto;">
									<v-list-item-group color="primary" v-model="selectedMrTpl">
										<v-list-item v-for="li in mrTpls" :key="li._id">
											<v-list-item-title>{{li.title}}</v-list-item-title>
											<v-list-item-title class="text-right">{{li.creator ? '' : '公用'}}</v-list-item-title>
										</v-list-item>
									</v-list-item-group>
								</v-list>
							</v-tab-item>
							<v-tab-item>
								<v-list dense>
									<v-list-item-group color="primary" v-model="selectedMoTpl">
										<v-list-item v-for="li in moTpls" :key="li._id">
											<v-list-item-title>{{li.title}}</v-list-item-title>
										</v-list-item>
									</v-list-item-group>
								</v-list>
							</v-tab-item>
						</v-tabs-items>
					</div>
					<div class="d-flex" style="gap:4px;padding:4px;">
						<v-btn color="primary" style="flex-grow:1;" @click.stop="applyMrs">引用</v-btn>
						<v-btn color="primary" style="flex-grow:1;" @click.stop="deleteMrs" :disabled="(tab!==1)||(selectedMrTpl===null)||(selectedMrTpl===undefined)">删除</v-btn>
						<v-btn color="primary" style="flex-grow:1;" @click.stop="tplSheet=false">返回</v-btn>
					</div>
				</div>
				<Medicalrecord :mrs="refMrs" :mos="refMos" readonly/>
			</v-sheet>
		</v-bottom-sheet>
		<v-navigation-drawer right absolute permanent :mini-variant.sync="mini" width="50%" v-if="dialog">	<!-- https://stackoverflow.com/questions/75266211 -->
			<div style="position:sticky;top:0;z-index:99;padding:4px 8px;">
				<v-btn @click.stop="mini = !mini" color="primary" dark fab x-small>
					<v-icon v-if="mini" dark>mdi-chevron-left</v-icon>
					<v-icon v-else dark>mdi-chevron-right</v-icon>
				</v-btn>
			</div>
			<viewer v-if="rpts.length > 0" @inited="inited" ref="viewer" :images="rpts.filter(x=>!x.isPdf)">
				<template slot-scope="scope">
					<div v-for="src in scope.images" style="position:relative">
						<span v-if="!mini" style="position:absolute;left:14px;top:4px;color:#777;">{{src.StudyDate}}</span>
						<img :src="src.valueOf()" :key="src.valueOf()" style="width:100%;margin:4px 8px;box-shadow:6px 6px 6px #ddd;">
					</div>
				</template>
			</viewer>
			<div v-for="r in rpts.filter(x=>x.isPdf)" width="100%" style="padding:4px 0 4px 8px;">
				<object :data="r.valueOf()+'#navpanes=0&view=FitH'" type="application/pdf" :height="mini?68:1000" style="width:100%;"/>
			</div>
		</v-navigation-drawer>
	</v-dialog>
</template>

<script>
	import { Splitpanes, Pane } from 'splitpanes'
	import 'splitpanes/dist/splitpanes.css'
	import print from 'vue-print-nb'
    import {formatDate, calculateAge} from '../utils'
	import Medicalrecord from '../components/MedicalRecord.vue';

    export default {
		name: 'Diagnose',
		directives: {
			print   
		},
        components: { Splitpanes, Pane, Medicalrecord },
		props: {
			value: Boolean,
			patient: {
				type: Object,
				default: () => {pii:{}}
			},
			mrindex: {
				type: Number,
				default: -1
			}
		},
        data() {
            return {
				authed: false,
				mritems: [
					'主诉',
					'现病史',
					'既往史',
					'家族史',
					'手术史',
					'过敏史',
					'体格检查',
					'辅助检查',
					'诊断',
					'处置'
				],
				mrs: {tags:[]},
				mos: [],
				refMrs: {},
				refMos: [],
				tab: null,
				selectedHistory: null,
				moHeaders: [
					{text:'分类', value:'mi.项目分类', width:100},
					{text:'项目', value:'mi.项目名称', width:200},
					{text:'剂量', value:'mi.基本剂量', width:100},
					{text:'用法', value:'mo.routes', width:120},
					{text:'每次用量', value:'mo.dose', width:120},
					{text:'频次', value:'mo.times', width:120},
					{text:'天数', value:'mo.days', width:80},
					{text:'数量', value:'mo.amount', width:80},
				],
				tplSheet: false,
				mrTpls: [],
				moTpls: [],
				selectedMrTpl: null,
				selectedMoTpl: null,
				index: this.mrindex,
				curdate: '',
				curoperatorName: '',
				cursignature: '',
				printObj: {
					id: 'printme4d',
				},
				rpts: [],
				mini: true,
				loading: false,
				printObjMo: {
					id: 'printmo',
				},
            }
        },
		created() {
			this.formatDate = formatDate;
			this.getAge = calculateAge;
			this.authed = this.$hasPrivilege(['消化内科','麻醉科','门诊']);
			if (!this.authed) return;
			this.fetchTemplates();
		},
		computed: {
			dialog: {
				get() {
					return this.value;
				},
				set(v) {
					if (!v) {
						this.$emit('input', false);
					}
				}
			},
		},
		watch: {
			value(v) {
				if (v) {
					let operator;
					this.index = this.mrindex;
					if (this.index === -1) {
						this.mrs = {tags:[]};
						this.mos = [];
						this.curdate = formatDate(new Date());
						this.curoperatorName = this.$getCurrentUser().name || this.$getCurrentUser().username;
						operator = this.$getCurrentUser().username;
					} else {
						const mr = this.patient.MedicalHistory[this.index];
						this.mrs = Object.assign({}, mr.mrs);
						this.mos = Object.assign([], mr.mos);
						this.curdate = formatDate(mr.time);
						this.curoperatorName = mr.operatorName;
						operator = mr.operator;
					}
					const db = this.$tcbapp.database();
					db.collection('wp2doctor').where({username:operator}).field({signature:true}).get().then(res => {
						if (res.data.length > 0) {
							this.cursignature = res.data[0].signature;
						} else {
							this.cursignature = null;
						}
					});
					this.mini = true;
					this.fetchRpts();
				}
			},
			selectedHistory(newitem) {
				if (newitem !== undefined) {
					const v = this.patient.MedicalHistory[newitem];
					this.refMrs = v.mrs || {};
					this.refMos = v.mos || [];
				} else {
					this.refMrs = {};
					this.refMos = [];
				}
			},
			selectedMrTpl(newitem) {
				if (newitem !== undefined) {
					const v = this.mrTpls[newitem];
					this.refMrs = v.template;
				} else {
					this.refMrs = {};
				}
			},
			selectedMoTpl(newitem) {
				if (newitem !== undefined) {
					const v = this.moTpls[newitem];
					this.refMos = v.template;
				} else {
					this.refMos = [];
				}
			},
		},
		methods: {
			inited(viewer) {
				this.$viewer = viewer;
			},
			async fetchRpts() {
				this.rpts = [];
				const s = this.patient;
				const {username, dob} = s.pii;
				const res = await this.$getEdReports({username, dob});
				const s1 = res.map(x => {
					const r = new String(x.imagesrc);
					r.StudyDate = x.StudyDate;
					return r;
				});
				const s2 = s.pathologyReports ? s.pathologyReports.map(x => new String(x)) : [];
				s2.forEach(x => {
					const str = x.valueOf();
					x.isPdf = str.substring(str.length - 3).toLowerCase() === 'pdf';
					x.StudyDate = '';
				});
				/*
				const s3 = s.refFiles ? s.refFiles.map(x => {
					const r = new String(x.filepath);
					r.StudyDate = formatDate(x.time);
				}) : [];
				console.log(s3)
				*/
				this.rpts = s2.concat(s1);
			},
			async fetchTemplates() {
				const username = this.$getCurrentUser().username;
				const db = this.$tcbapp.database();
				const _ = db.command;
				try {
					const resR = await db.collection('mrtemplate').where({category:'mr', creator:_.eq(username).or(_.exists(false))}).get();
					const resO = await db.collection('mrtemplate').where({category:'mo'}).get();
					this.mrTpls = resR.data;
					this.moTpls = resO.data;
//					this.mrTpls = res.data.filter(x => x.category === 'mr');
//					this.moTpls = res.data.filter(x => x.category === 'mo');
				} catch(err) {
					console.error(err);
				}
			},
			async endEdit() {
				this.dialog = false;
				this.$emit('close');
			},
			async save() {
				for (let k in this.mrs) {
					if (k !== 'tags') {
						let v = this.mrs[k];
						if (!v || !v.trim()) {delete this.mrs[k]} else {this.mrs[k] = v.trim()}
					}
				}
				this.loading = true;
				try {
					if (this.index === -1) {
						const res = await this.$tcbapp.callFunction({name:"wp2mp",
							data:{
								funcname:'addMedicalRecord',
								data: {
									id: this.patient._id,
									mrs: this.mrs,
									mos: this.mos
								}
							}
						});
						this.index = res.result - 1;
					} else {
						const res = await this.$tcbapp.callFunction({name:"wp2mp",
							data:{
								funcname:'modifyMedicalRecord',
								data: {
									id: this.patient._id,
									index: this.index,
									mrs: this.mrs,
									mos: this.mos
								}
							}
						});
						if (!res.result.ret) {
							this.$dialog.error({title:'保存失败', text:res.result.msg});
							return;
						}
					}
					this.$emit('change');
					this.$dialog.message.success('保存完成');
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('保存失败');
				} finally {
					this.loading = false;
				}
			},
			async applyMrs() {
				switch(this.tab) {
					case 0:
						this.mrs = this.refMrs ? Object.assign({tags:[]}, this.refMrs) : {};
						this.mos = this.refMos ? this.refMos.slice() : [];
						break;
					case 1:
						this.mrs = this.refMrs ? Object.assign({tags:[]}, this.refMrs) : {};
						break;
					case 2:
						this.mos = this.refMos ? this.refMos.slice() : [];
						break;
				}
			},
			async deleteMrs() {
				const item = this.mrTpls[this.selectedMrTpl];
				if (!item) return;
				const res = await this.$dialog.confirm({
					text: '确定要删除此条目？',
					title: '提示'
				});
				if (!res) return;
				this.loading = true;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname:'deleteMrTemplate',
							data: {
								id: item._id,
							}
						}
					});
					this.mrTpls.splice(this.selectedMrTpl, 1);
					this.$dialog.message.success('删除完成');
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('删除失败');
				}
				this.loading = false;
			},
			async onappend(item) {
				if (!this.patient?._id) return '';
				const db = this.$tcbapp.database();
				const _ = db.command;
				try {
					const res = await db.collection('wp2order').doc(this.patient._id).field({reports:true}).get();
					const rpt = res.data[0]?.reports;
					if (rpt) {
						let rs = '';
						if (rpt['胃镜']?.diagnoses) rs += rpt['胃镜']?.diagnoses + '\n';
						if (rpt['肠镜']?.diagnoses) rs += rpt['肠镜']?.diagnoses;
						return rs;
					} else {
						this.$dialog.message.warning('无法获取报告内容');
					}
				} catch(err) {
					console.error(err);
				}
				return '';
			}
		}
    }
</script>

<style>
.diagnoserightpanel {
	width:360px;
	border-left:1px solid #eee;
	padding:4px;
	background-color:#f3f3f3;
	display:flex;
/*	flex-flow: row wrap;*/
	flex-flow: column;
}
.prttable {
	width:100%;
	border:none!important;
}
.prttable td {
	border:none!important;
	font-size:14px!important;
	line-height:24px!important;
}
.prttable1 {
	width:100%;
	border:none!important;
	font-size:11px!important;
}
.prttable1 td {
	border:none!important;
	font-size:11px!important;
	line-height:18px!important;
}
</style>