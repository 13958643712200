<template>
	<div style="width:100%;">
	<splitpanes class="default-theme">
		<pane min-size="25" size="40">
			<v-form class="overflow-y-auto" style="height:100%">
				<v-container>
					<div v-if="!readonly" class="mb-3">
						<v-btn color="primary" width="100" @click.stop="clearMrTpl" outlined>清空</v-btn>
						<v-btn color="primary" width="100" @click.stop="saveMrTpl" class="ml-2" outlined>保存为模板</v-btn>
						<v-chip-group multiple id="cgmr" style="display:inline-flex;margin-left:40px;" active-class="success" v-model="mrs.tags">
							<v-chip v-for="item in mrtagsAll" :key="item" filter :value="item">{{item}}</v-chip>
						</v-chip-group>
					</div>
					<v-textarea :outlined="!readonly" :filled="readonly" v-for="item in mritems" :key="item" :label="item" v-model="mrs[item]"
						:readonly="readonly" :disabled="!mrs[item] && readonly" :append-icon="item==='诊断'&&!readonly ? 'mdi-content-duplicate' : null" @click:append.stop="onClickAppend(item)"/>
				</v-container>
			</v-form>
		</pane>
		<pane min-size="25" size="60">
			<div class="overflow-y-auto" style="height:100%">
			<v-container v-if="!readonly">
				<v-row>
					<v-col cols="4">
						<v-menu v-model="menu" transition="scale-transition" :nudge-right="4" offset-y min-width="auto"
							:close-on-content-click="false" :close-on-click="false">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="pname" label="医嘱项目" dense hide-details v-bind="attrs" v-on="on"
									clearable append-icon="search" @click:append="fetchData" @click:clear="pname='';fetchData();" @change="fetchData" class="pname"/>
							</template>
							<v-data-table :headers="headers1" :items="items1" item-key="_id"
								:options.sync="options" :server-items-length="totalItems"
								:footer-props="{ itemsPerPageOptions: [10, 30, 50] }" fixed-header
								:loading="loading" loading-text="加载中..."	@click:row="showDlg" v-click-outside="{include:include, handler:()=>menu=false}"/>
						</v-menu>
					</v-col>
					<v-col cols="4">
						<v-btn color="primary" width="100" @click.stop="clearMoTpl" outlined>清空</v-btn>
						<v-btn color="primary" width="100" @click.stop="saveMoTpl" class="ml-2" outlined>保存为模板</v-btn>
					</v-col>
				</v-row>
			</v-container>
			<v-data-table :headers="computedHeaders" :items="mos" :items-per-page="-1">
				<template v-slot:item.actions="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-icon v-bind="attrs" v-on="on" class="mr-2" @click.stop="editMo(item)">mdi-square-edit-outline</v-icon>
						</template>
						<span>编辑</span>
					</v-tooltip>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-icon v-bind="attrs" v-on="on" class="mr-2" @click.stop="delMo(item)">mdi-delete-forever-outline</v-icon>
						</template>
						<span>删除</span>
					</v-tooltip>
				</template>
			</v-data-table>
			</div>
		</pane>
	</splitpanes>
	<v-dialog v-model="dlg1" width="600">
		<v-card>
			<v-card-title>医嘱项目</v-card-title>
			<v-card-text class="pb-0">
				<v-form ref="mform">
				<v-container>
					<v-row>
						<v-col cols="12"><v-text-field label="项目名称" readonly filled dense hide-details :value="curItem['项目名称']"/></v-col>
					</v-row>
					<v-row>
						<v-col cols="12"><v-text-field label="生产厂家" readonly filled dense hide-details :value="curItem['生产厂家']"/></v-col>
					</v-row>
					<v-row>
						<v-col cols="6"><v-text-field label="规格" readonly filled dense hide-details :value="curItem['规格']"/></v-col>
						<v-col cols="6"><v-text-field label="基本剂量" readonly filled dense hide-details :value="curItem['基本剂量']" :suffix="curItem['剂量单位']+'/'+curItem['单位']"/></v-col>
					</v-row>
					<v-row>
						<v-col cols="4"><v-select label="用法" hide-details :items="drugRoutes" v-model="curMo.routes"/></v-col>
						<v-col cols="4"><v-text-field label="每次用量" type="number" min="0" :suffix="curItem['单位']" v-model.number="curDose"/></v-col>
						<v-col cols="4"><v-text-field label="每次剂量" readonly filled outlined :suffix="curItem['剂量单位']" :value="curDosis"/></v-col>
					</v-row>
					<v-row>
						<v-col cols="4"><v-select label="频次" hide-details :items="drugTimes" v-model="curMo.times"/></v-col>
						<v-col cols="4"><v-text-field label="天数" type="number" min="0" v-model.number="curMo.days"/></v-col>
						<v-col cols="4"><v-text-field label="数量" readonly filled outlined :suffix="curItem['单位']" :value="curAmount"/></v-col>
					</v-row>
				</v-container>
				</v-form>
			</v-card-text>
			<v-divider/>
			<v-card-actions>
				<v-spacer/>
				<v-btn color="primary" @click.stop="saveMo" :loading="loading" :disabled="loading">确定</v-btn>
				<v-btn @click.stop="dlg1=false">返回</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
	<v-dialog v-model="dlgSaveTpl" width="400">
		<v-card>
			<v-card-title>保存模板</v-card-title>
			<v-card-text>
				<v-container>
					<v-form ref="tplform" class="d-flex">
						<v-text-field label="模板名称" v-model.trim="tplname" :rules="txtRules"/>
						<v-checkbox label="公用" v-model="tplpublic" class="ml-4"/>
					</v-form>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer/>
				<v-btn color="primary" @click.stop="doSaveMrTpl" :loading="loading" :disabled="loading">保存</v-btn>
				<v-btn @click.stop="dlgSaveTpl=false">返回</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
	</div>
</template>

<script>
	import { Splitpanes, Pane } from 'splitpanes'
	import 'splitpanes/dist/splitpanes.css'

	export default {
		components: {Splitpanes, Pane},
		props: {
			readonly: {
				type: Boolean,
				default: false
			},
			mrs: {
				type: Object,
				default: () => {tags:[]}
			},
			mos: {
				type: Array,
				default: () => []
			},
			onappend: {
				type: Function,
				default: async (item) => ''
			},
		},
		data() {
			return {
				loading: false,
				mritems: [
					'主诉',
					'现病史',
					'既往史',
					'家族史',
					'手术史',
					'过敏史',
					'体格检查',
					'辅助检查',
					'诊断',
					'处置'
				],
				moheaders: [
					{text:'操作', value:'actions', width:100},
					{text:'项目', value:'mi.项目名称', width:200},
					{text:'剂量', value:'mi.基本剂量', width:100},
					{text:'用法', value:'mo.routes', width:120},
					{text:'每次用量', value:'mo.dose', width:120},
					{text:'频次', value:'mo.times', width:120},
					{text:'天数', value:'mo.days', width:80},
					{text:'数量', value:'mo.amount', width:80},
				],
				menu: false,
				pname: '',
				headers1: [
					{text:'类别', value:'项目分类', width:100},
					{text:'项目', value:'项目名称', width:240},
					{text:'规格', value:'规格', width:160},
					{text:'单位', value:'单位', width:80},
					{text:'备注', value:'备注', width:180},
				],
				items1: [],
				options: {},
				totalItems: 0,
				dlg1: false,
				curItem: {},
				drugRoutes: [
					'口服',
					'皮下注射',
					'肌肉注射',
					'输液',
					'外用',
					'静脉滴注',
					'静脉持续',
					'滴眼',
					'滴鼻',
					'滴耳',
					'注射'
				],
				drugTimes: [
					'立即',
					'1次/日',
					'2次/日',
					'3次/日',
					'4次/日',
				],
				numberRules: [v => parseInt(v) == v || '请填写数字'],
				txtRules: [(v) => v && (v.length >= 1) || '不能为空'],
				curMo: {
					routes: null,
					times: null,
					days: null
				},
				curDose: null,
				moEditing: null,
				mrtagsAll: ['保险'],
				dlgSaveTpl: false,
				tplname: '',
				tplpublic: false,
			}
		},
		computed: {
			computedHeaders() {
				return this.readonly ? this.moheaders.slice(1) : this.moheaders;
			},
			curDosis() {
				return (this.curDose||0) * (this.curItem['基本剂量']||0);
			},
			curAmount() {
				if (!this.curMo || !this.curDose || (this.curMo.times===null) || (this.curMo.days===null)) return null;
				if (this.curMo.times === '立即') return this.curDose;
				return this.drugTimes.indexOf(this.curMo.times) * this.curDose * this.curMo.days;
			},
		},
		watch: {
			options: {
				handler() {
					this.fetchData()
				},
				deep: true,
			},
		},
		methods: {
			include () {
        		return [document.querySelector('.pname')]
      		},
			async fetchData() {
				this.loading = true;
				try {
					const db = this.$tcbapp.database();
					const _ = db.command;
					const f = [{'_id':_.exists(true)}];
					if (this.pname) {
						const filter = _.or([{
							'拼音码': db.RegExp({
								regexp: this.pname,
								options: 'i'
							})}, {
							'项目名称': db.RegExp({
								regexp: this.pname,
								options: 'i'
							})
						}]);
						f.push(filter);
					}
					if (this.itemcls) {
						f.push({'项目分类':this.itemcls});
					}
					f.push({'产地':'*'});
                    const countRes = await db.collection('kbjitems').where(_.and(f)).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('kbjitems').where(_.and(f)).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items1 = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			showDlg(item) {
				this.curItem = item;
				this.curMo = Object.assign({}, this.$options.data().curMo);
				this.curDose = null;
				this.moEditing = null;
				this.dlg1 = true;
			},
			editMo(item) {
				this.curItem = item.mi;
				this.curMo = Object.assign({}, item.mo);
				this.curDose = item.mo.dose;
				this.moEditing = item;
				this.dlg1 = true;
			},
			saveMo() {
				if (!this.$refs.mform.validate()) return;
				if (this.moEditing) {
					this.moEditing.mo = Object.assign({}, this.curMo);
					this.moEditing.mo.dose = this.curDose;
					this.moEditing.mo.dosis = this.curDosis;
					this.moEditing.mo.amount = this.curAmount;
				} else {
					const mi = Object.assign({}, this.curItem);
					const mo = Object.assign({}, this.curMo);
					mo.dose = this.curDose;
					mo.dosis = this.curDosis;
					mo.amount = this.curAmount;
					this.mos.push({mi, mo});
				}
				this.dlg1 = false;
				this.menu = false;
			},
			async delMo(item) {
				const res = await this.$dialog.confirm({
					text: '确定要删除此条目？',
					title: '提示'
				});
				if (res) {
					const index = this.mos.indexOf(item);
					if (index > -1) this.mos.splice(index, 1);
				}
			},
			async saveMrTpl() {
				for (let k in this.mrs) {
					if (k !== 'tags') {
						let v = this.mrs[k];
						if (!v || !v.trim()) {delete this.mrs[k]} else {this.mrs[k] = v.trim()}
					}
				}
				if (Object.keys(this.mrs).length === 0) {
					this.$dialog.message.error('不能保存空模板');
					return;
				}
				if (Object.keys(this.mrs).length === 1 && !!this.mrs.tags) {
					this.$dialog.message.error('不能保存空模板');
					return;
				}
				this.tplname = '';
				this.tplpublic = false;
				this.dlgSaveTpl = true;
			},
			async doSaveMrTpl() {
				if (!this.$refs.tplform.validate()) return;
				this.loading = true;
				let MRS = Object.assign({}, this.mrs);
				if (MRS.tags) delete MRS.tags;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname:'saveMrTemplate',
							data: {
								category: 'mr',
								title: this.tplname,
								template: MRS,
								public: this.tplpublic,
							}
						}
					});
					this.$dialog.message.success('保存完成');
					this.$emit('updatetemplate');
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('保存失败');
				}
				this.dlgSaveTpl = false;
				this.loading = false;
			},
			async clearMrTpl() {
				for (let member in this.mrs) {
					if (member !== 'tags') {
						this.mrs[member] = null;
						delete this.mrs[member];
					}
				}
			},
			async saveMoTpl() {
				if (this.mos.length === 0) {
					this.$dialog.message.error('不能保存空模板');
					return;
				}
				const res = await this.$dialog.prompt({
					text: '模板名称',
					rules: [(v) => v && (v.length >= 1) || '模板名称不能为空'],
				});
				if (!res) return;
				this.loading = true;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname:'saveMrTemplate',
							data: {
								category: 'mo',
								title: res,
								template: this.mos
							}
						}
					});
					this.$dialog.message.success('保存完成');
					this.$emit('updatetemplate');
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('保存失败');
				}
				this.loading = false;
			},
			async clearMoTpl() {
				this.mos.splice(0, this.mos.length)
			},
			async onClickAppend(item) {
				if (!this.onappend) return;
				try {
					const a = await this.onappend(item);
					this.$set(this.mrs, item, a);
				} catch (err) {
					console.error(err);
				}
			},
		},
	}
</script>

<style>
.splitpanes.default-theme .splitpanes__pane {
    background-color: white;
}
.splitpanes.default-theme .splitpanes__splitter {
    background-color: #eee;
}
.splitpanes.default-theme .splitpanes__splitter:after,
.splitpanes.default-theme .splitpanes__splitter:before {
    background-color: rgba(0, 0, 0, .25);
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter,
.default-theme .splitpanes--horizontal>.splitpanes__splitter {
    height: 8px;
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme.splitpanes--horizontal>.splitpanes__splitter:before,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:before {
    width: 60px;
    height: 1.5px;
}
#cgmr .v-slide-group__wrapper {
	contain: none;
}
</style>